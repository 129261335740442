import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const RelatedContent = (props) => {
  return (
    <Container className="related-content section mt-2 pt-1">
      {props.page === "social-toolkit" && (
        <Row>
          <Col xs={12} className="m-auto ">
            <h2 className="title">RELATED CONTENT</h2>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} className="m-auto">
          <Container>
            <Row className="justify-content-lg-between">
              <a
                className="light-grey related-content-card mb-3 mb-lg-4"
                href="../../landscape/hiv-testing"
              >
                <h3 className="related-content-card-header">HIV Testing</h3>
                <p className="related-content-card-subtext">
                  Learn how HIV testing is the first step in getting people
                  diagnosed, linking them to care, and providing an opportunity
                  to discuss treatment or prevention options.
                </p>

                <span className="read-more">
                  <span className="font-heavy">
                    <span className="more-link">Learn more</span>
                  </span>
                  <span className="empty-space">&nbsp;&nbsp;&nbsp;</span>
                  <i className="fa fa-chevron-right "></i>
                </span>
              </a>
              <a
                className="light-grey related-content-card mb-3 mb-lg-4"
                href="../../landscape/ehe"
              >
                <h3 className="related-content-card-header">
                  Plans for Ending the Epidemic&nbsp;
                </h3>
                <p className="related-content-card-subtext">
                  Discover more about the goals and coordinated efforts to
                  address the HIV epidemic across government agencies in the
                  United States.
                </p>
                <span className="read-more">
                  <span className="font-heavy">
                    <span className="more-link">Discover more</span>
                  </span>
                  <span className="empty-space">&nbsp;&nbsp;&nbsp;</span>
                  <i className="fa fa-chevron-right "></i>
                </span>
              </a>
              <a
                className="light-grey related-content-card mb-3 mb-lg-4"
                href="../../community-involvement/voices-of-strength"
              >
                <h3 className="related-content-card-header">
                  Voices of Strength Video Series
                </h3>
                <p className="related-content-card-subtext">
                  Watch the personal stories of people who combat stigma through
                  their perseverance and strength.
                </p>
                <span className="read-more">
                  <span className="font-heavy">
                    <span className="more-link">Watch more</span>
                  </span>
                  <span className="empty-space">&nbsp;&nbsp;&nbsp;</span>
                  <i className="fa fa-chevron-right "></i>
                </span>
              </a>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default RelatedContent;
