import * as React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import RelatedContent from "./related";
import HTML5Video from "../global/video.js";
import series from "../../images/Blind-angels-series-trailer-thumbnail.png";
import seriesVideo from "../../video/blind-angels_trailer.mp4";
import tony from "../../images/Blind-angels-tony-video-thumbnail.png";
import tonyVideo from "../../video/gba_ep1_compressed.mp4";
import jose from "../../images/Blind-angels-jose-joaquin-video-thumbnail.png";
import joseVideo from "../../video/gba_ep2_compressed.mp4";
import zakia from "../../images/Blind-angels-zakia-video-thumbnail.png";
import zakiaVideo from "../../video/gba_ep3_compressed.mp4";
import dazon from "../../images/Blind-angels-dazon-video-thumbnail.png";
import dazonVideo from "../../video/gba_ep4_compressed.mp4";
import kayla from "../../images/Blind-angels-kayla-video-thumbnail.png";
import kaylaVideo from "../../video/gba_ep5_compressed.mp4";
import BackToTop from "../global/BackToTop.js";
import Accordions from "./Accordions.js";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";

class Content extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      VideoSrc: undefined,
      VideoThumb: undefined,
    };
  }

  scrollToSection = (section) => {
    setTimeout(() => {
      let element = $('h2[data-anchor="' + section + '"]');
      if (element.parent("button").hasClass("active")) {
        let position = element.offset().top;
        let offset = $(window).width() <= 574 ? 100 : 30;
        window.scrollTo({ top: position - offset, behavior: "smooth" });
      }
    }, 500);
  };

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClick = (video) => {
    let VideoSrc;
    let VideoThumb;
    let VideoTrackLabel;

    switch (video) {
      case "series":
        VideoThumb = series;
        VideoSrc = seriesVideo;
        VideoTrackLabel = "Series Trailer";
        break;
      case "tony":
        VideoThumb = tony;
        VideoSrc = tonyVideo;
        VideoTrackLabel = "Birmingham, AL: Tony Christon-Walker";
        break;
      case "jose":
        VideoThumb = jose;
        VideoSrc = joseVideo;
        VideoTrackLabel = "Durham, NC: José Romero";
        break;
      case "zakia":
        VideoThumb = zakia;
        VideoSrc = zakiaVideo;
        VideoTrackLabel = "Richmond, VA: Zakia McKensey";
        break;
      case "dazon":
        VideoThumb = dazon;
        VideoSrc = dazonVideo;
        VideoTrackLabel = "Atlanta, GA: Dázon Dixon Diallo";
        break;
      case "kayla":
        VideoThumb = kayla;
        VideoSrc = kaylaVideo;
        VideoTrackLabel = "Memphis, TN: Kayla Rena Gore";
        break;
      default:
        break;
    }
    this.setState({
      show: true,
      VideoThumb: VideoThumb,
      VideoSrc: VideoSrc,
      VideoTrackLabel: VideoTrackLabel,
    });
  };

  render() {
    return (
      <div
        className={`${
          this.props.show ? "d-block" : "d-none"
        } social-media-toolkit`}
      >
        <Container
          fluid
          className="hero social-media-toolkit"
          role="img"
          aria-label="Group of people with their arms on each other’s shoulders"
        />
        <Container className="blind-angels">
          <Row>
            <Col xs={10} lg={10} className="m-auto px-0">
              <div className="hero-callout muted-yellow-dark">
                <h1 className="sub-title ">Social Media Toolkit</h1>
                <h1 className="title ">PREPARE FOR HIV AWARENESS DAYS</h1>
              </div>
              <p className="header-text">
                HIV awareness days call attention to the impact of HIV and the
                national efforts to help end the epidemic. To build your own
                awareness day posts, you can access downloadable assets below and a suggested caption for ease of
                posting. All assets can be shared on Facebook, X, and
                Instagram. This page will be updated throughout the year with
                new awareness day posts.
              </p>

              <h3 className="title">DOWNLOADING ASSETS FROM THE TOOLKIT</h3>
              <Container className="pt-3">
                <Row>
                  <Col xs={3} lg={1} className="px-0 pb-4">
                    <span className="circle">1</span>
                  </Col>
                  <Col xs={9} lg={3} className="px-0 pb-4">
                    <p className="download-text double-line">
                      Select an awareness <br />
                      day below
                    </p>
                  </Col>
                  <Col xs={3} lg={1} className="px-0 pb-4">
                    <span className="circle">2</span>
                  </Col>
                  <Col xs={9} lg={3} className="px-0 pb-4">
                    <p className="download-text">
                      Copy and <br className="desktop" />
                      paste caption
                    </p>
                    <p>
                      If you choose to share this post with your followers, we
                      encourage you to use the captions below as provided.
                    </p>
                  </Col>
                  <Col xs={3} lg={1} className="px-0 pb-4">
                    <span className="circle">3</span>
                  </Col>
                  <Col xs={9} lg={3} className="px-0 pb-4">
                    <p className="download-text">Download image</p>
                    <ul className="download-image-ul">
                      <li>
                        <strong>For desktop:</strong> Choose “Download” beneath
                        your preferred image
                      </li>
                      <li>
                        <strong>For mobile:</strong> Long-press your preferred
                        image, and then save/download
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
              <h3 className="title">Awareness Day Social Posts</h3>
              <Container>
                <Row>
                  <Col xs={12} className="px-0">
                    <Accordions scrollToSection={this.scrollToSection} />
                  </Col>
                </Row>
              </Container>

              <RelatedContent page="social-toolkit" />
            </Col>
            <Col xs={12} className="px-0">
              <BackToTop />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Content;
