import * as React from "react";
import { Component } from "react";
import { Col } from "react-bootstrap";
import ArrowUp from "../../images/arrow-up.png";

class BackToTop extends Component {
  constructor(props) {
    super();
  }
  onClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <>
        <Col
          xs={6}
          md={2}
          className="py-2 mx-auto text-center margin-top-minus30px"
        >
          <div
            onClick={this.onClick}
            className="mb-3 back-to-top w-auto px-0 font-heavy"
            /* data-analytics-event-category="Buttons"
            data-analytics-event-label="HIV Testing: Back To Top" */
          >
            <img className="d-block m-auto pb-2" src={ArrowUp} />
            <span>Back to Top</span>
          </div>
        </Col>
      </>
    );
  }
}

export default BackToTop;
